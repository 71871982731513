import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import { Email } from '~/services/utils/Email'
import { PreAuthUser, UserAuthToken, GoogleAuthToken, SendPasswordResetEmailResponse, User } from '~/services/types/User'
import { Service } from '~/services/types/Service'

interface IUserService {
  $axios: NuxtAxiosInstance
  authenticate(email: Email, password: string): Promise<UserAuthToken>
  preAuthenticate(email: Email, password: string): Promise<PreAuthUser>
  refreshToken(token: string): Promise<UserAuthToken>
  getUserInformation(): Promise<User>
  logout(): Promise<void>
  getUserServices(): Promise<Service>
  sendPasswordResetEmail(email: Email): Promise<SendPasswordResetEmailResponse>
  validateGoogleToken(token: string): Promise<GoogleAuthToken>
  validateMFA(code: string, ephemeralToken: string): Promise<UserAuthToken>
  validatePasswordResetToken(loginId: string, token: string): Promise<boolean>
  submitNewPassword(loginId: string, token: string, newPassword: string): Promise<UserAuthToken>
  submitFBTokenToEmissary(token: string, tenantId: string): Promise<any>
}

export default class UserService implements IUserService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  authenticate (email: Email, password: string): Promise<UserAuthToken> {
    return this.$axios.$post('/authenticate', { email, password })
  }

  preAuthenticate (email: Email, password: string): Promise<PreAuthUser> {
    return this.$axios.$post('/pre-auth', { email, password })
  }

  refreshToken (token: string): Promise<UserAuthToken> {
    return this.$axios.$post('/refresh-auth-token', { token })
  }

  getUserInformation (): Promise<User> {
    return this.$axios.$get('/ping')
  }

  logout (): Promise<void> {
    return this.$axios.$post('/logout')
  }

  getUserServices (): Promise<Service> {
    const random = Math.random()
    return this.$axios.$get('/services', { params: { random } })
  }

  sendPasswordResetEmail (email: Email): Promise<SendPasswordResetEmailResponse> {
    return this.$axios.$post('/api/user/request-reset-token', { email })
  }

  validateGoogleToken (token: string): Promise<GoogleAuthToken> {
    return this.$axios.$get('/social/complete/google-plus/', { params: { id_token: token } })
  }

  validateMFA (code: string, ephemeralToken: string): Promise<UserAuthToken> {
    return this.$axios.$post('/api/validate-mfa', { code, ephemeral_token: ephemeralToken })
  }

  validatePasswordResetToken (loginId: string, token: string): Promise<boolean> {
    return this.$axios.$post('/api/user/check-reset-token', {
      login_id: loginId,
      token
    })
  }

  submitNewPassword (loginId: string, token: string, newPassword: string): Promise<UserAuthToken> {
    return this.$axios.$post('/api/user/use-reset-token', {
      login_id: loginId,
      password: newPassword,
      token
    })
  }

  submitFBTokenToEmissary (token: string, tenantId: string): Promise<any> {
    return this.$axios.$get(`/api/whatsapp/tenant/${tenantId}/waba/setup?code=${token}`)
  }
}
